<template>
  <div ><v-img :src="require(`../../assets/${imgsrc}`)"  style="object-fit: cover; filter: brightness(50%);" width="100%" height="500px" alt="">
  </v-img>
  <h1 class="white--text text-center "  style="    position: absolute;
    top: 350px;
    left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
}" >{{prop}}</h1>
  </div>
</template>

<script>
export default {
props:['prop', 'imgsrc'],
data() {
    return {
    }
},
 created(){
  
    },
}
</script>

<style>

</style>